import React, { FC, useEffect } from 'react';
import { Layout } from '../redesign';

const ReferAFriend: FC = () => {
  useEffect(() => {
    // Why are we using a Timer?
    // See https://parsleyhealth.atlassian.net/browse/TIG-467
    setTimeout(function() {
      window.friendbuyAPI.push(['track', 'page', { name: 'refer-a-friend' }]);
    }, 2000);
  });

  return (
    <Layout>
      <div id="friendbuylandingpage"></div>
    </Layout>
  );
};

export default ReferAFriend;
